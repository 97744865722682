import { Table as BaseTable, Center, LoadingOverlay, Pagination, Select, Space, Text, createStyles } from "@mantine/core";
import { IconChevronDown, IconChevronUp, IconDatabaseOff, IconSelector } from "@tabler/icons";
import qs from "qs";
import React, { useImperativeHandle } from "react";
import useSwr from "swr";
import { IFilter } from "../../interfaces/IFilter";

export * from "./row-action";

export type ColumnType<T> = {
  title: string | React.ReactNode;
  sorter?: boolean;
  dataIndex?: string;
  render: (record: T, index: number) => JSX.Element | string;
  renderTitle?: (record: T, index: number) => JSX.Element | string;
  align?: "left" | "right" | "center";
  width?: string | number;
};

type Props = {
  name: string;
  columns: ColumnType<any>[];
  filters?: { [key: string]: string | number | any };
  pagination?: boolean;
  dataSource?: any[];
  loadData?: (filter?: IFilter) => Promise<any>;
  minWidth?: number;
  withColumnBorders?: boolean;
  placeholder?: string;
};

export type ITableRef = { reload: () => void };

export const Table = React.forwardRef(
  (
    { name, columns, filters = {}, placeholder, pagination = true, dataSource = [], loadData, minWidth, withColumnBorders = false }: Props,
    ref: React.Ref<ITableRef>,
  ) => {
    const { classes } = useStyles();
    const scrollRef = React.useRef<HTMLDivElement>(null);
    const tableRef = React.useRef<HTMLTableElement>(null);
    const [offset, setOffset] = React.useState({ page: 1, limit: 20 });

    const { data, mutate, isLoading } = useSwr(
      `table.${name}.[${offset.page}, ${offset.limit}]?${qs.stringify(filters)}`,
      async () => loadData && (await loadData({ offset, filter: filters } as IFilter)),
      {
        fallbackData: { count: dataSource.length, rows: dataSource },
      },
    );
    const [sorted, setSorting] = React.useState<string[]>([]);

    const onSort = (index?: string) => {
      if (index) setSorting((state) => [index, state[0] === index ? (state[1] === "asc" ? "desc" : "asc") : "asc"]);
    };

    const onScroll = () => {
      // console.log(tableRef.current?.clientWidth);
      // console.log(scrollRef.current?.clientWidth);
      // let classLeft;
      // let classRight;
      // let scrollLeft = Math.abs((tableRef.current?.clientWidth || 0) - (scrollRef.current?.clientWidth || 0)) || 0;
      // classRight = (scrollRef.current?.scrollLeft || 0) < scrollLeft ? "ant-table-ping-right" : "";
      // classLeft = (scrollRef.current?.scrollLeft || 0) > 0 ? "ant-table-ping-left" : "";
      // console.log(classLeft);
      // console.log(classRight);
      // setScrolling(classLeft + " " + classRight);
    };

    useImperativeHandle(ref, () => ({
      reload() {
        return mutate();
      },
    }));

    return (
      <div className={classes.container}>
        <div ref={scrollRef} onScroll={onScroll} className={classes.scroll}>
          <BaseTable
            withColumnBorders={withColumnBorders}
            ref={tableRef}
            className={classes.table}
            horizontalSpacing="md"
            verticalSpacing="xs"
            highlightOnHover>
            <thead>
              <tr>
                {columns.map((column, index) => (
                  <Th key={index} column={column} sorted={sorted[0] === column.dataIndex && sorted[1]} onSort={() => onSort(column.dataIndex)}>
                    <Text align={column.align || "left"}>
                      {column.title}
                      {column.renderTitle && column.renderTitle(data, index)}
                    </Text>
                  </Th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.rows?.length > 0 ? (
                data?.rows?.map((row: any, index: number) => {
                  return (
                    <tr key={`${index}`}>
                      {columns.map((column, index2) => {
                        return (
                          <td key={`${index2}`} className={classes.td}>
                            {column.render(row, index)}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr key={"Noting"}>
                  <td colSpan={columns.length} className={classes.td}>
                    <Center sx={(theme) => ({ padding: theme.spacing.lg, flexDirection: "column", gap: 10 })}>
                      <IconDatabaseOff size={36} stroke={1.2} />
                      <Text weight={500} align="center">
                        {placeholder || "Юу ч олдсонгүй"}
                      </Text>
                    </Center>
                  </td>
                </tr>
              )}
            </tbody>
          </BaseTable>

          <Space h="sm" />

          <LoadingOverlay visible={isLoading} />
        </div>

        {pagination && (
          <div className={classes.pagination}>
            <Pagination
              page={offset.page}
              onChange={(page) => setOffset((state) => ({ ...state, page: page }))}
              total={data.count / offset.limit + (data.count % offset.limit > 0 ? 1 : 0)}
            />
            {data.count > 0 && (
              <>
                <Space w={"xs"} />
                <Select
                  onChange={(e: string) => {
                    setOffset((state) => ({ ...state, limit: parseInt(e), page: 1 }));
                  }}
                  value={`${offset.limit}`}
                  w={"100px"}
                  size="xs"
                  defaultValue={"10"}
                  data={["5", "10", "20", "30", "50", "100", "200"]}
                />
              </>
            )}
          </div>
        )}
      </div>
    );
  },
);

interface ThProps {
  children: React.ReactNode;
  column: ColumnType<any>;
  sorted?: string | unknown;
  onSort(): void;
}

const Th = ({ children, column, sorted, onSort }: ThProps) => {
  const { classes } = useStyles();
  const Icon = sorted ? (sorted === "asc" ? IconChevronUp : IconChevronDown) : IconSelector;

  if (!column.sorter)
    return (
      <th className={classes.th} style={{ width: column.width }}>
        {children}
      </th>
    );

  return (
    <th className={`${classes.th} ${classes.control}`} style={{ width: column.width || "max-content" }} onClick={() => onSort()}>
      <div className={classes.button}>
        <span className={classes.child}>{children}</span>
        <span className={classes.icon}>
          <Icon size={14} stroke={1.5} />
        </span>
      </div>
    </th>
  );
};

const useStyles = createStyles((theme) => ({
  collapseTh: {
    width: "24px",
  },
  collapseContent: {
    // background: "red",
    borderTop: "1px solid #dadada",
    padding: "14px 24px",
  },
  tdDetail: {
    padding: "0 !important",
    border: "none !important",
  },
  container: {
    position: "relative",
  },
  scroll: {
    overflowX: "auto",
  },
  button: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },
  child: {
    display: "flex",
  },
  icon: {
    display: "flex",
  },
  pagination: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    margin: "15px 0",
    alignItems: "center",
  },
  table: {
    minWidth: 1000,
  },
  th: {
    padding: "8px!important",
    lineHeight: "14px",
    whiteSpace: "nowrap",
    "&:last-child": {
      // background: "red",
      textAlign: "center",
    },
  },
  td: {
    padding: "8px!important",
  },
  control: {
    cursor: "pointer",
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },
}));
