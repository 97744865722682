import { ActionIcon, Badge, Flex, Group, Modal, Tabs, Text, Tooltip } from "@mantine/core";
import { IconBell, IconBellRinging, IconCreditCard, IconFileLike, IconFileText, IconFilter } from "@tabler/icons";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RepaymentApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { PayForm } from "../../components/repayment/pay-form";
import { PaymentMatching } from "../../components/repayment/payment-matching";
import { ColumnType, Table } from "../../components/table";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";
import { dateTimeFormat } from "../../utils/date";
import { Tag } from "./../../components/tags";
import { Repayment } from "./repayment";

export const RepaymentList = () => {
  const breadcrumbs = useBreadcrumb();
  const [action, setAction] = useState<any[]>([]);

  const {
    currencies,
    productSuppFeeRules,
    repaymentStatus = [],
    programRepaymentRules = [],
    repaymentPaymentStatus,
    repaymentOverDueStatus = [],
  } = useSelector((state: { general: IGeneral }) => state.general);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const tableRef = useRef<any>(null);

  const BellTypes = (record: any) => {
    return (
      <Group noWrap>
        {record?.remainingDays >= 2 && record?.remainingDays <= 3 && (
          <ActionIcon variant="light" color="green">
            <IconBell />
          </ActionIcon>
        )}

        {record?.remainingDays === 1 && (
          <ActionIcon variant="light" color="yellow">
            <IconBell />
          </ActionIcon>
        )}

        {record?.remainingDays === 0 && (
          <ActionIcon variant="light" color="orange">
            <IconBell />
          </ActionIcon>
        )}

        {record?.remainingDays < 0 && (
          <ActionIcon variant="light" color="red">
            <IconBellRinging />
          </ActionIcon>
        )}
      </Group>
    );
  };

  const columns = useHeader({
    onClick: async (key, record) => {
      if (record.id) {
        try {
          let res = await RepaymentApi.get(record.id);
          setAction([key, res || record]);
        } catch (error) {
          console.log(error);
        }
      }
    },
    Tag,
    repaymentStatus,
    currencies,
    productSuppFeeRules,
    programRepaymentRules,
    repaymentPaymentStatus,
    repaymentOverDueStatus,
    BellTypes,
    user: user,
  });

  const reloadTable = async () => {
    await tableRef.current.reload();
  };

  return (
    <PageLayout
      title="Эргэн төлөлтийн нэхэмжлэх"
      subTitle="Эргэн төлөх нэхэмжлэхийн жагсаалт"
      breadcrumb={breadcrumbs}
      extra={[
        <Group key={0}>
          <ActionIcon variant="light" size={36} color="golomt">
            <IconFilter size={25} />
          </ActionIcon>
        </Group>,
      ]}>
      <Tabs defaultValue={"2"}>
        <Tabs.List>
          {/* <Tabs.Tab py={"md"} value="1">
            Зээлийн шугамаар
          </Tabs.Tab> */}
          <Tabs.Tab py={"md"} value="2" disabled={user?.currentBusiness?.type === "BUYER"}>
            Нэхэмжлэх барьцаалах
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="1">
          <Table
            ref={tableRef}
            name="product.list.BUYER_LED.false"
            columns={columns}
            filters={{
              query: "",
              // productType: "BUYER_LED",
              isRecalled: false,
            }}
            minWidth={2400}
            loadData={(data) => RepaymentApi.list(data!)}
          />
        </Tabs.Panel>
        <Tabs.Panel value="2">
          <Table
            ref={tableRef}
            name="product.list.SUPPLIER_LED"
            columns={columns}
            filters={{
              query: "",
              // productType: "SUPPLIER_LED",
              isRecalled: false,
            }}
            minWidth={2400}
            loadData={(data) => RepaymentApi.list(data!)}
          />
        </Tabs.Panel>
      </Tabs>
      <Repayment action={action} setAction={setAction} reloadTable={reloadTable} />

      <Modal
        centered
        size="1200px"
        withCloseButton={false}
        opened={action[0] === "pay"}
        padding={0}
        onClose={() => {
          setAction([]);
        }}>
        <PayForm
          action={action}
          setAction={setAction}
          reload={async () => {
            await tableRef.current?.reload();
          }}
        />
      </Modal>

      <Modal
        centered
        size={"60%"}
        title={
          <Text fw={600} fz="lg">
            Төлөлт тохирох
          </Text>
        }
        opened={action[0] === "payment_match"}
        onClose={() => {
          setAction([]);
        }}>
        <PaymentMatching action={action} setAction={setAction} reload={reloadTable} />
      </Modal>
    </PageLayout>
  );
};

const useHeader = ({
  onClick,
  programRepaymentRules,
  repaymentPaymentStatus,
  repaymentOverDueStatus,
  repaymentStatus,
  Tag,
  BellTypes,
  user,
}: {
  onClick: (key: string, record: any) => void;
  currencies: IReference[];
  productSuppFeeRules: any;
  programRepaymentRules: any;
  repaymentPaymentStatus: IReference[];
  repaymentOverDueStatus: IReference[];
  repaymentStatus: IReference[];
  Tag: any;
  BellTypes: any;
  user: any;
}): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
  },
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap={8} wrap="nowrap">
          {BellTypes(record)}
          {record?.overdueStatus !== "NORMAL" && ( //&& record?.overdueStatus !== "ONE_THREE"
            <Tooltip label="Төлөлт тохирох" position="top">
              <ActionIcon variant="light" onClick={() => onClick("payment_match", record)} color="">
                <IconFileLike />
              </ActionIcon>
            </Tooltip>
          )}
          {user.currentBusiness.type === "BUYER" && (
            <Tooltip label="Төлөх" position="top">
              <ActionIcon disabled={record?.paymentStatus === "PAID"} variant="light" onClick={() => onClick("pay", record)} color="">
                <IconCreditCard />
              </ActionIcon>
            </Tooltip>
          )}
          <Tooltip label="Дэлгэрэнгүй" position="top">
            <ActionIcon variant="light" onClick={() => onClick("drawer", record)} color="">
              <IconFileText />
            </ActionIcon>
          </Tooltip>
        </Flex>
      );
    },
  },
  {
    title: "ЭТН Код",
    render: (record) => (
      <Text fw={500} color="golomt" w="max-content">
        # {record?.refCode}
      </Text>
    ),
  },
  {
    title: "ЭТН төлөх дүн",
    render: (record) => (
      <Flex justify="flex-end">
        <Text fw={500} color="gray.7" w="max-content">
          {record?.repaymentAmount ? currencyFormat(record?.repaymentAmount) : "-"}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Эргэн төлөх огноо",
    render: (record) => (
      <Text fw={500} color="gray.7" w="max-content">
        {record?.repaymentDate && dateTimeFormat(record?.repaymentDate || "-")}
      </Text>
    ),
  },
  {
    title: "Төлсөн дүн",
    render: (record) => (
      <Flex justify="flex-end">
        <Text fw={500} color="gray.7" w="max-content">
          {record?.paidAmount ? currencyFormat(record?.paidAmount) : "-"}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Төлбөрийн үлдэгдэл",
    render: (record) => (
      <Flex justify="flex-end">
        <Text fw={500} color="gray.7" w="max-content">
          {record?.amountToPay ? currencyFormat(record?.amountToPay) : "-"}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Төлбөрийн төлөв",
    render: (record) => (
      <div>
        {Tag(
          repaymentPaymentStatus.find((item: IReference) => item.code === record?.paymentStatus)?.color || "indigo",
          repaymentPaymentStatus.find((item: IReference) => item.code === record?.paymentStatus)?.name || "",
        )}
      </div>
    ),
  },
  {
    title: "Хугацаа хэтрэлт",
    render: (record) => {
      if (record?.overdueStatus >= 0)
        return (
          <Text fw={500} color="gray.7" w="max-content">
            {Tag(
              repaymentOverDueStatus.find((item: IReference) => item.code === record?.overdueStatus)?.color || "indigo",
              repaymentOverDueStatus.find((item: IReference) => item.code === record?.overdueStatus)?.name || "",
            )}
          </Text>
        );
      else
        return (
          <Text fw={500} color="gray.7" w="max-content">
            {Tag(
              repaymentOverDueStatus.find((item: IReference) => item.code === record?.overdueStatus)?.color || "indigo",
              repaymentOverDueStatus.find((item: IReference) => item.code === record?.overdueStatus)?.name || "",
            )}
          </Text>
        );
    },
  },
  {
    title: "Нэхэмжлэх",
    render: (record) => (
      <Text color="golomt" fw={500} w="max-content">
        # {record?.invRefCode}
      </Text>
    ),
  },
  {
    title: "Хүсэлт Код",
    render: (record) => (
      <Text color="golomt" fw={500} w="max-content">
        # {record?.scfRequestRefCode}
      </Text>
    ),
  },
  {
    title: "Үүсгэсэн огноо",
    render: (record) => (
      <Text color="gray.7" fw={500} w={"max-content"}>
        {dayjs(record?.createdAt).format("YYYY-DD-MM HH:mm")}
      </Text>
    ),
  },
  {
    title: "Хүсэлтийн төлөв",
    render: (record) => (
      <Text fw={500} color="gray.7" w="max-content">
        {Tag(
          repaymentStatus?.find((item: IReference) => item?.code === record?.repaymentStatus)?.color || "",
          repaymentStatus?.find((item: IReference) => item?.code === record?.repaymentStatus)?.name || "-",
        )}
      </Text>
    ),
  },
  {
    title: "Нэхэмжлэгч тал",
    render: (record) => (
      <Text fw={500} color="gray.7" w="max-content">
        {record?.receiver}
      </Text>
    ),
  },
  {
    title: "Хүлээж буй хугацаа",
    render: (record) => {
      if (record?.remainingDays >= 0) {
        return (
          <Text fw={500} color="indigo" w={"max-content"}>
            {record?.remainingDays} хоног
          </Text>
        );
      } else
        return (
          <Text fw={500} color="red" w={"max-content"}>
            {Math.abs(record?.remainingDays)} хоног хэтэрсэн байна.
          </Text>
        );
    },
  },
  {
    title: "Нэхэмжлэх төлөх огноо",
    render: (record) => (
      <Text fw={500} color="gray.7" w="max-content">
        {record?.invDueDate ? dayjs(record?.invDueDate).format("YYYY-DD-MM HH:mm") : "-"}
      </Text>
    ),
  },
  {
    title: "Буцаан дуудах",
    render: (record) => (
      <Text fw={500} w="max-content">
        {record?.recourseTerm ? (
          <Badge color="green" size="sm">
            Тийм
          </Badge>
        ) : (
          <Badge color="red" size="sm">
            Үгүй
          </Badge>
        )}
      </Text>
    ),
  },
  {
    title: "Зогсоох огноо",
    render: (record) => (
      <Text fw={500} color="gray.7" w="max-content">
        {dayjs(record?.restrictDate).format("YYYY-DD-MM HH:mm")}
      </Text>
    ),
  },
  {
    title: "Алданги (Хувь)",
    render: (record) => (
      <Text fw={500} color="gray.7" w={110}>
        {record?.penaltyPercent} %
      </Text>
    ),
  },
  {
    title: "Эргэн төлөх дүрэм",
    render: (record) => (
      <Text fw={500} color="gray.7" w="max-content">
        {programRepaymentRules?.find((mk: any) => mk?.code === record?.repaymentRule)?.name || "-"}
      </Text>
    ),
  },
  {
    title: "Нийлүүлэгч бизнес",
    render: (record) => (
      <div>
        <Text fw={500} color="gray.7" w="max-content">
          {record?.payerBusiness?.profileName}
        </Text>
        <Text fw={500} color="golomt" component="a">
          # {record?.payerBusiness?.refCode}
        </Text>
      </div>
    ),
  },
  {
    title: "Нийлүүлэгч партнер",
    render: (record) => (
      <div>
        <Text fw={500} color="gray.7" w="max-content">
          {record?.payerBusiness?.partner?.businessName}
        </Text>
        <Text fw={500} color="golomt" component="a">
          # {record?.payerBusiness?.partner?.refCode}
        </Text>
      </div>
    ),
  },
  {
    title: "Олголт код",
    render: (record) => (
      <Text fw={500} color="golomt" w="max-content">
        # {record?.disbursementRef || "-"}
      </Text>
    ),
  },
  {
    title: "Төлөлт авах данс",
    render: (record) => (
      <Text fw={500} color="gray.7" w="max-content">
        {record?.receiverAcc?.number}
      </Text>
    ),
  },
  {
    title: "Дансны нэр",
    render: (record) => (
      <Text fw={500} color="gray.7" w="max-content">
        {record?.receiverAcc?.name}
      </Text>
    ),
  },
  {
    title: "Төлөлт авах банк",
    render: (record) => (
      <Text fw={500} color="gray.7" w="max-content">
        {record?.receiverAcc?.bankName}
      </Text>
    ),
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/",
    label: "Санхүүжилт",
  },
  {
    label: "Эргэн төлөлтүүд",
  },
];
